import React from 'react';
import './../css/NotFoundPage.css';

export default function NotFoundPage() {
    return (
        <div className={'errorPage'}>
            <h1>404</h1>
            <img src={'/blitzerLogo.png'} alt={'blitzerLogo'}/>
            <p>There was an error. The requested page does not exist.</p>
        </div>
    );
}