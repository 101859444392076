import React from "react";
import '../css/BurgerMenu.css';

export default function BurgerMenu(props) {

    return (
        <div onClick={props.changeMobileMenuVisibility} id={'burgerMenu'}>
            <span id='burgerMenuSpan1'/>
            <span id='burgerMenuSpan2'/>
            <span id='burgerMenuSpan3'/>
        </div>
    );
}