import React, {useEffect, useState} from "react";
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet'
import axios from "axios";
import './../css/Home.css';

export default function Home() {
    const [streakers, setStreakers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'streaker').then((response) => {
            let streakerList = [];
            response.data.forEach(streaker => {
                streakerList.push(streaker);
            });
            if (streakerList.length === 0) {
                setLoading(false);
            }
            setStreakers(streakerList);
        });
    }, []);

    function calculateDuration(streaker) {
        let duration = Math.floor(Date.now() / 1000) - streaker.lastActivated;
        let durationString = '';
        if (duration >= 86400) {
            durationString += Math.floor(duration / 86400) + 'd ';
            duration -= Math.floor(duration / 86400) * 86400
        }
        if (duration >= 3600) {
            durationString += Math.floor(duration / 3600) + 'h ';
            duration -= Math.floor(duration / 3600) * 3600
        }
        if (duration >= 60) {
            durationString += Math.floor(duration / 60) + 'min';
        }
        return durationString;
    }

    if (!loading) {
        return (
            <div>
                <h1>mobile Blitzer</h1>
                <p>Freie Fahrt für freie Bürger</p>
            </div>
        );
    } else if (streakers.length === 0) {
        return (
            <div>
                <h1>mobile Blitzer</h1>
                <p>Daten werden geladen...</p>
            </div>
        );
    } else {
        return (
            <div>
                <h1>mobile Blitzer</h1>
                {streakers.map((streaker, index) => {
                    return (
                        <React.Fragment key={'ReactFragment ' + index}>
                            <div key={'streakerWrapper ' + index} className={'streakerWrapper'}>
                                <p key={'name ' + index}>{streaker.street + (streaker.partlyFixed ? ' (Anhänger)' : '')}</p>
                                <p key={'duration ' + index} className={'duration'}>{calculateDuration(streaker)}</p>
                            </div>
                        </React.Fragment>
                    )
                })}
                <MapContainer
                    className={'map'}
                    center={[51.48, 11.97]}
                    zoom={12}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {streakers.map((streaker, index) => {
                        if (0 === streaker.latitude && 0 === streaker.longitude) {
                            return null;
                        }

                        return (
                            <Marker key={'Marker ' + index} position={[streaker.latitude, streaker.longitude]}>
                                <Popup key={'Popup ' + index}>{streaker.street}</Popup>
                            </Marker>
                        );
                    })};
                </MapContainer>
            </div>
        );
    }
}