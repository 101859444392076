import React from "react";
import './../css/MobileMenu.css';
import {Link} from "react-router-dom";

export default function MobileMenu(props) {

    return (
        <div id='mobileMenu' className='hidden'>
            <ul onClick={props.changeMobileMenuVisibility}>
                <li>
                    <Link to='/'>mobile Blitzer</Link>
                </li>
                <li>
                    <Link to='/fixedStreaker'>feste Blitzer</Link>
                </li>
                <li>
                    <Link to='/speedometer'>Tacho</Link>
                </li>
            </ul>
        </div>
    );
}