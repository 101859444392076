import React from "react";
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet'
import './../css/Home.css';

export default function FixedStreaker() {

    return (
        <>
            <h1>feste Blitzer</h1>
            <MapContainer
                className={'map'}
                center={[51.48, 11.97]}
                zoom={12}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[51.48151796566449, 11.944577423966058]}>
                    <Popup>beidseitig</Popup>
                </Marker>
                <Marker position={[51.46463343134032, 11.953929044483683]}>
                    <Popup>stadteinwärts</Popup>
                </Marker>
                <Marker position={[51.44987288962563, 11.984078207126744]}>
                    <Popup>stadteinwärts</Popup>
                </Marker>
                <Marker position={[51.480429799393534, 11.932447608584674]}>
                    <Popup>stadteinwärts</Popup>
                </Marker>
                <Marker position={[51.48569030230736, 11.984432670241802]}>
                    <Popup>stadteinwärts</Popup>
                </Marker>
                <Marker position={[51.459207604135926, 11.995808692615647]}>
                    <Popup>Richtung B6</Popup>
                </Marker>
            </MapContainer>
        </>
    )
}