import React, {useState} from "react";
import {ReactComponent as Logo} from "./../images/blitzerLogo.svg";
import './../css/Header.css';
import BurgerMenu from "./BurgerMenu";
import {Link} from "react-router-dom";
import MobileMenu from "./MobileMenu";

export default function Header() {
    const [showMobileMenu, setShowMobileMenu] = useState(false);


    const changeMobileMenuVisibility = () => {
        const mobileMenu = document.getElementById('mobileMenu');
        const span1 = document.getElementById('burgerMenuSpan1');
        const span2 = document.getElementById('burgerMenuSpan2');
        const span3 = document.getElementById('burgerMenuSpan3');

        if (showMobileMenu) {
            setShowMobileMenu(false);
            mobileMenu.classList.replace('openMobileMenu', 'closeMobileMenu');
            span1.classList.replace('openSpan1', 'closeSpan1');
            span2.classList.replace('hidden', 'showSpan2');
            span3.classList.replace('openSpan3', 'closeSpan3');

            setTimeout(() => {
                mobileMenu.classList.replace('closeMobileMenu', 'hidden')
                span1.classList.remove('closeSpan1');
                span3.classList.remove('closeSpan3');

            }, 500);
        } else {
            setShowMobileMenu(true);
            mobileMenu.classList.replace('hidden', 'openMobileMenu');
            span1.classList.add('openSpan1');
            span2.classList.add('hidden');
            span3.classList.add('openSpan3');
        }
    }

    return (
        <React.Fragment>
            <MobileMenu changeMobileMenuVisibility={changeMobileMenuVisibility}/>
            <div className={'header'}>
                <BurgerMenu changeMobileMenuVisibility={changeMobileMenuVisibility}/>
                <Logo/>
                <div className='profile'/>
            </div>
            <nav>
                <ul>
                    <li>
                        <Link to='/'>mobile Blitzer</Link>
                    </li>
                    <li>
                        <Link to='/fixedStreaker'>feste Blitzer</Link>
                    </li>
                    <li>
                        <Link to='/speedometer'>Tacho</Link>
                    </li>
                </ul>
            </nav>
        </React.Fragment>
    );
}