import React from "react";
import Home from "./components/Home";
import NotFoundPage from "./components/NotFoundPage";
import FixedStreaker from "./components/FixedStreaker";
import Speedometer from "./components/Speedometer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './App.css';
import Layout from "./components/Layout";

export default function App() {
    return (
        <BrowserRouter>
                <Routes>
                    <Route element={<Layout/>}>
                        <Route exact path='/' element={<Home/>}/>
                        <Route exact path='/fixedStreaker' element={<FixedStreaker/>}/>
                        <Route path='*' element={<NotFoundPage/>} status={404}/>
                    </Route>
                    <Route exact path='/speedometer' element={<Speedometer/>}/>
                </Routes>
        </BrowserRouter>
    );
}
