import React from "react";
import './../css/SpeedSign.css';

export default function SpeedSign(props) {

    return (
        <>
            {0 === props.speedLimit ?
                <div className='circle-unlimited'>
                    <div className='circle-line'/>
                    <div className='circle-line'/>
                    <div className='circle-line'/>
                    <div className='circle-line'/>
                    <div className='circle-line'/>
                </div> :
                <div className='circle'>
                    <p className={props.speedLimit > 99 ? 'speedLimitHigh' : 'speedLimitLow'}>
                        {props.speedLimit}
                    </p>
                </div>}
        </>
    )
}