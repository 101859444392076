import React, {useEffect, useState} from "react";
import axios from "axios";
import './../css/Speedometer.css';
import SpeedSign from "./SpeedSign";

export default function Speedometer() {

    const [speedLimit, setSpeedLimit] = useState('?');
    const [currentRoad, setCurrentRoad] = useState('-');
    const [speed, setSpeed] = useState(null);

    function updateSpeed(position) {
        if (null !== position.coords.speed) {
            setSpeed(Math.round(position.coords.speed * 3.6));
        }
    }

    function getTrackInformation() {
        navigator.geolocation.getCurrentPosition((position => {
            axios.get(process.env.REACT_APP_API_URL + 'streetInformation?coordinates=' + position.coords.latitude + ',' + position.coords.longitude)
                .then((response) => {
                    setCurrentRoad(response.data.name ?? '-');
                    setSpeedLimit(response.data.speedLimit ?? '?');
                });
        }));
    }

    useEffect(() => {
        let wakeLock = null;
        try {
            wakeLock = navigator.wakeLock.request("screen");
        } catch (e) {
        }

        navigator.geolocation.watchPosition(updateSpeed, null, {
            enableHighAccuracy: true,
        });

        getTrackInformation();
        let trackingInformationIntervalId = setInterval(getTrackInformation, 5000);

        return () => {
            clearInterval(trackingInformationIntervalId);
            wakeLock.release().then(() => {
                wakeLock = null;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<div id='speedometerWrapper'>
        <SpeedSign speedLimit={speedLimit}/>
        {null === speed ? null :
            <div id='wrapperSpeed'>
                <p>{speed + ' km/h'}</p>
            </div>}
        <div id='wrapperRoad'>
            <p>{currentRoad}</p>
        </div>
    </div>);
}